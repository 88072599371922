module.exports = {
  background: '#FFFFFF',
// Blue-gray lines
  backgroundDark: '#e6ebf6',

// Font in main page
  primary: '#4b4b4b',
// Type in projects and some lines
  primaryLight: '#ff883e',
// Right Triangle
  primaryDark: '#009543',
//
  secondary: '#00008b',
//
  secondaryLight: '#ff883e',
// Font in second page
  secondaryDark: '#000000',
};
